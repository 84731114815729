import React from "react";
import { Container, Grid, Typography } from "@mui/material";
import Footer from "../Footer";
import Header from "../Header";
import { palette } from "../getTheme";

function AboutPage() {
    return (<>
        <Header />
        <div style={{ background: palette.secondary.main, paddingBottom: "30px" }}>
            <Container maxWidth="sm" sx={{ paddingTop: "30px" }}>
                <Grid container justify="center">
                    <Typography variant="h1" sx={{ fontSize: "2.5rem", fontWeight: "600" }} gutterBottom>
                        About us
                    </Typography>

                    <Typography variant="body1" sx={{ marginBottom: "30px" }}>
                        The founder of Floness, Matti Saarinen, had worked for years in companies (Apple, Nokia, Talenom) that were willing to use AI and automation in the business processes. However, AI is hard and he saw it first-hand how difficult it is to build hybrid processes that employ AI yet have human experts being responsible for the result. How can a human expert rely on AI? How to really use AI to remove human work or decrease the difficulty of the work?
                    </Typography>
                    <Typography variant="body1" sx={{ marginBottom: "30px" }}>
                        The best business processes will be executed by a combination of human experts, customers, code and AI. The remaining human work will become easier than it ever was.
                    </Typography>
                    <Typography variant="body1" sx={{ marginBottom: "30px" }}>
                        Every company that does business has business processes. They are not always defined or even recognized. A small business owner may claim to have no business processes. Obviously, that’s not true. Every business has business processes that form the operations of the company.

                    </Typography>
                    <Typography variant="body1" sx={{ marginBottom: "30px" }}>
                        First step in refining a critical business process is to define the actual process. Only recognized processes can be improved and automated.

                    </Typography>
                    <Typography variant="body1" sx={{ marginBottom: "30px" }}>
                        All business processes can be defined as a flow tree with different nodes and branches. There are generally four types of actions:
                        <ul>
                            <li>A judgment by an employee/expert</li>
                        <li>A further question to customer</li>
                        <li>A threshold function or a filter based on data, ie. code</li>
                        <li>A decision by AI</li>
                        </ul>
                        Those are the parts that will form the final workflows that realize the business process definitions. By Floness.
                    </Typography>

                </Grid>
            </Container>
        </div>
        <div style={{ background: palette.tertiary.main, paddingBottom: "0px" }}>
            <Container maxWidth="sm" sx={{ paddingTop: "30px" }}>
                <Grid container justify="center">
                    
                    <Typography variant="h1" sx={{ width: "100%", fontSize: "2.5rem", fontWeight: "600" }} gutterBottom>
                        Founder
                    </Typography>
                    </Grid></Container></div>
                    <div style={{ background: palette.tertiary.main, paddingBottom: "30px" }}>
            <Container maxWidth="sm" sx={{ paddingTop: "30px" }}>
                <Grid container justify="center">
            
                    <Grid item xs={12} sm={4} md={3} lg={3} sx={{textAlign: "left", paddingLeft: "20px"}}>
        <img src="/mattisaarinen.jpeg" alt="Matti Saarinen" style={{maxWidth: "140px", width: "100%", borderRadius: "10% 10% 10% 10%", border: "solid 1px #ccc"}} />
                    </Grid>
                    <Grid item xs={12} sm={8} md={9} lg={9} sx={{paddingLeft: "20px"}}> 
                    <Typography variant="h2" sx={{ fontSize: "1.5rem" }} gutterBottom>
                        Matti Saarinen
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        Matti is a serial founder of software businesses. He has sold two businesses: a 3D tool business to Apple, and a FinTech consulting company to Talenom Plc.
                        </Typography>
                    <Typography variant="body1" gutterBottom>
                        
                         He is a software engineer by education, experience and identity. 
                        </Typography>
                    <Typography variant="body1" sx={{ marginBottom: "20px" }}>
                    When not working, he’s probably happy and busy with his family of 10 kids and a lovely wife!
                    </Typography>
                    <a href="https://fi.linkedin.com/in/matti-saarinen-4b756b2" target="_blank" rel="noreferrer" style={{paddingBottom: "20px"}}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" 
                    data-supported-dps="24x24" fill="rgb(45,100,188)" class="mercado-match" width="50" height="50" focusable="false" style={{position: "relative", left: "-4px;"}}><path d="M20.5 2h-17A1.5 1.5 0 002 3.5v17A1.5 1.5 0 003.5 22h17a1.5 1.5 0 001.5-1.5v-17A1.5 1.5 0 0020.5 2zM8 19H5v-9h3zM6.5 8.25A1.75 1.75 0 118.3 6.5a1.78 1.78 0 01-1.8 1.75zM19 19h-3v-4.74c0-1.42-.6-1.93-1.38-1.93A1.74 1.74 0 0013 14.19a.66.66 0 000 .14V19h-3v-9h2.9v1.3a3.11 3.11 0 012.7-1.4c1.55 0 3.36.86 3.36 3.66z"></path></svg>
        </a>    </Grid>
                    
                </Grid>
            </Container>
        </div>
        <div style={{ background: palette.secondary.main, paddingBottom: "30px" }}>
            <Container maxWidth="sm" sx={{ paddingTop: "30px" }}>
                <Grid container>
                    <Typography variant="h1" sx={{ display: "block", width: "100%", fontSize: "2.5rem", fontWeight: "600" }} gutterBottom>
                        Careers
                    </Typography>

                    <Typography variant="body1" gutterBottom>
                        We at Floness would love to get help from
                        <ul>
                            <li>
                                junior software developers
                            </li>
                            <li>
                                senior software developers
                            </li>
                            <li>
                                business development folks
                            </li>
                        </ul>
                    </Typography>
                    <Typography variant="body1" gutterBottom>

                        Floness apps will be built with node.js and React.
                    </Typography>
                    <Typography variant="body1" gutterBottom>

                        Please send an application to matti@floness.com
                    </Typography>






                </Grid>
            </Container>
        </div>
        <Footer />
    </>);
}

export default AboutPage;
