import { createTheme } from '@mui/material';

const palette = {
    primary: {
        main: '#eeeeee',
    },
    secondary: {
        main: 'rgb(230, 239, 235)',
    },
    tertiary: {
        main: 'rgb(243, 254, 251)'
    }
};

const theme = createTheme({
    typography: {
        button: {
            color:"black",
            textTransform: 'none',
          fontFamily: 'Instrument Sans', // You can change the font family to your desired font
          fontSize: '1rem', // Adjust the font size as needed
          // You can also specify other typography properties like fontWeight, fontStyle, etc.
        },
      },
    components: {
        MuiPaper: {
            defaultProps: {
                elevation: 3,
            },
        },
        MuiTypography: {
            defaultProps: {
                fontFamily: "'Instrument Sans', sans-serif",
            },
            overrides: {

            h1: {
                fontSize: "40px"
            }}
        },
        MuiGrid: {

            '&.footer': {
                backgroundColor: "red" // palette.secondary.main,
            },

        },
    },
    palette,
    overrides: {
        MuiGrid: {
            root: {
                '&.footer': {
                    backgroundColor: 'blue',
                },
            },
        },

        MuiTypography: {
            h1: {
                fontSize: '40px',
            },
        },

    },
});

function getTheme() {
    return theme;
}

export default getTheme;

export { palette }
