import React, { useState } from "react";

let step = 0;
let stepFunction
const processLength = 250
let direction = 1

setInterval(() => {
    if (step > processLength) direction = -4
    if (step < -50) direction = 1

    step += direction

    if (stepFunction)
        stepFunction(step)
}, 30)

let svgIdCounter = 0;

function WorkSplit(props) {

    const [_step, setStepState] = useState(0)
    stepFunction = setStepState

    const [svgId] = useState(`svg_WorkSplit_${svgIdCounter++}`)

    const opacity = Math.max(0, Math.min(step / (processLength - 50), 1))
    return (
        <svg
            width="100%"
            height="256"
            viewBox="0 0 204.4308 80.358772"
            version="1.1"
            id={svgId}
        >
            <defs
                id="defs1">
                <linearGradient
                    id="linearGradient4">
                    <stop
                        style={{ stopColor: "#000000", stopOpacity: 1 }}
                        offset="0"
                        id="stop5" />
                    <stop
                        style={{ stopColor: "#222222", stopOpacity: 1 }}
                        offset="0.11926606"
                        id="stop15" />
                    <stop

                        style={{ stopColor: "#cfcfcf", stopOpacity: 1 }}
                        offset="0.16819572"
                        id="stop14" />
                    <stop
                        style={{ stopColor: "#e5e5e5", stopOpacity: 1 }}
                        offset="0.23547401"
                        id="stop13" />
                    <stop
                        style={{ stopColor: "#727272", stopOpacity: 1 }}
                        offset="0.36085626"
                        id="stop7" />
                    <stop
                        style={{ stopColor: "#000000", stopOpacity: 1 }}
                        offset="0.42201835"
                        id="stop9" />
                    <stop
                        style={{ stopColor: "#191919", stopOpacity: 1 }}
                        offset="0.52905196"
                        id="stop12" />
                    <stop
                        style={{ stopColor: "#cdcdcd", stopOpacity: 1 }}
                        offset="0.65749234"
                        id="stop10" />
                    <stop
                        style={{ stopColor: "#a1a1a1", stopOpacity: 0.8627451 }}
                        offset="0.76452601"
                        id="stop8" />
                    <stop
                        style={{ stopColor: "#303030", stopOpacity: 1 }}
                        offset="0.83792049"
                        id="stop11" />
                    <stop
                        style={{ stopColor: "#ffffff", stopOpacity: 1 }}
                        offset="1"
                        id="stop6" />
                </linearGradient>
                <linearGradient

                    id="linearGradient6"
                    x1="5.1826863"
                    y1="48.571487"
                    x2="199.20229"
                    y2="48.571487"
                    gradientUnits="userSpaceOnUse" />
            </defs>
            <g
                id="layer1"

                transform="translate(-1.0710679,-7.6549596)">
                <rect
                    style={{ opacity: 0.438163, fill: "url(#linearGradient4)", fillOpacity: 1, stroke: "#000000", strokeWidth: 3.09999, strokeOpacity: 1 }}
                    id="rect1"
                    width="190.91962"
                    height="63.479568"
                    x="8"
                    y="14.831703"
                    ry="1" />
                <rect
                    style={{
                        opacity, fill: "#686868", fillOpacity: 1, stroke: "#9a9a9a", strokeWidth: 3.351,
                        strokeDasharray: "none", strokeOpacity: 1
                    }}
                    id="rect15"
                    width="36.15147"
                    height="75"
                    x="2.746568"
                    y="9"
                    ry="1" />
                <rect
                    style={{ opacity, fill: "#ffffff", fillOpacity: 1, stroke: "#9a9a9a", strokeWidth: "1", strokeOpacity: 1 }}
                    id="rect15-7"
                    width="36.15147"
                    height="75"
                    x="42.987492"
                    y="9"
                    ry="1" />
                <rect
                    style={{ opacity, fill: "#ffffff", fillOpacity: 1, stroke: "#9a9a9a", strokeWidth: "1", strokeDasharray: "none", strokeOpacity: 1 }}
                    id="rect15-7-4"
                    width="20.638847"
                    height="75"
                    x="183.19052"
                    y="9"
                    ry="1" />
                <rect
                    style={{ opacity, fill: "#5e5e5e", fillOpacity: 1, stroke: "#9a9a9a", strokeWidth: "3.35065", strokeOpacity: 1 }}
                    id="rect15-2"
                    width="36.15147"
                    height="75"
                    x="83.66584"
                    y="9"
                    ry="1" />
                <rect
                    style={{ fill: "#c6c6c6", opacity, fillOpacity: 1, stroke: "#9a9a9a", strokeWidth: "1", strokeOpacity: 1 }}
                    id="rect15-2-2"
                    width="27.770849"
                    height="75"
                    x="123.66097"
                    y="9"
                    ry="1" />
                <rect
                    style={{ opacity, fill: "#646464", fillOpacity: 1, stroke: "#9a9a9a", strokeWidth: "2.77411", strokeOpacity: 1 }}
                    id="rect15-2-9"
                    width="24.593969"
                    height="75"
                    x="154.92581"
                    y="9"
                    ry="1" />
            </g>
        </svg>
    );
}

export default WorkSplit