import React, { Fragment } from "react";
import { AppBar, BottomNavigation, Container, Grid, Link, Typography } from "@mui/material";
import { palette } from "./getTheme";
function Footer(props) {
    return (
        <div style={{ width: props.width, backgroundColor: palette.secondary.main,borderTop: "solid 1px #777" }}  >
            <Container sx={{}} maxWidth="lg" sx={{paddingTop: "20px"}}>
                <Grid container className="footer" sx={{ padding: "10px", paddingBottom: "80px" }} >
                    <Grid item xs={12} sm={4} md={4} sx={{fontSize: "0.9rem", paddingBottom: "10px", paddingRight: "20px"}}>
                    <Typography variant="body1" sx={{fontSize: "0.9rem"}} gutterBottom >
                        &copy; 2024 Floness Ltd.
                        </Typography>

                        <Typography variant="body1" sx={{fontSize: "0.9rem"}} >
                            Floness is a pioneering firm that will help businesses define, document and automate their business processes using a hybrid model: human experts and AI will work together.
                        </Typography>
                    </Grid>
                    {false && <Fragment>
                    <Grid item xs={12} sm={4} md={4}>
                        <Typography variant="h6" component="h6" gutterBottom>
                            <Link sx={{color: "black"}} href="/">Home</Link>
                        </Typography>
                        <Typography variant="h6" component="h6" gutterBottom>
                        <Link sx={{color: "black"}} href="/about">About us</Link>
                        </Typography>
                    </Grid>

                    <Grid item xs={12} sm={4} md={4}>
                        <Typography variant="body1" component="h6" gutterBottom>
                            Privacy policy
                        </Typography>
                        <Typography variant="body1" component="h6" gutterBottom>
                            Contact us
                        </Typography>
                    </Grid>


                    </Fragment>}

                </Grid>
            </Container>
        </div>

    )

}

export default Footer;