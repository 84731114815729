/* global Gradient */
import React, { Fragment, useEffect } from "react"
import { Box, Button, Container, Grid, TextField, Typography } from "@mui/material";
import Footer from "../Footer";
import Header from "../Header";

import { ThemeProvider } from '@mui/material';
import getTheme, { palette } from '../getTheme';
import LineConvergence from "../animations/LineConvergence";
import WorkSplit from "../animations/WorkSplit";
import Circles from "../animations/Circles";
import { useState } from "react";
import DoneIcon from '@mui/icons-material/Done';
import AddCardIcon from '@mui/icons-material/AddCard';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import AddModeratorIcon from '@mui/icons-material/AddModerator';
import GroupAddIcon from '@mui/icons-material/GroupAdd';

// import * as PixiJS from 'pixi.js';
// import FlowCanvas from "../components/FlowCanvas";
// import { getDefaultFlow } from "../core/Flow";
import ThreeJSView from "../animations/ThreeTexture";
// import TaskBox from "../components/TaskBox";
let gradientCreated = false


let rerenderFunction

window.onresize = () => {
    if(rerenderFunction)
        rerenderFunction(Math.random())
}
/*
const  { Application, Text, Sprite, Assets, Graphics, Container: PixiContainer  } = PixiJS;

const app = new Application();
let bezier;


const resolution =window.devicePixelRatio || 1;
let curveY = window.innerHeight * resolution * 0.6;
const containerSize = resolution * 100
const containerPosition = resolution == 1 ? 0 : resolution == 2 ? -50 : 0
const containerScale = 1/resolution * 100

function r(v) {
    return v;//  * resolution
}

let path

async function initPixiApp(canvasContainer) {
    await app.init({ resizeTo: canvasContainer, background: '#eeeeee', resolution: window.devicePixelRatio });

    canvasContainer.childNodes.forEach(child => canvasContainer.removeChild(child))
    canvasContainer.appendChild(app.canvas);

    // Listen for frame updates
    
    const createRoundedRectangle = (x, y, width, height, radius, title) => {

        const container = new PixiContainer({
            // this will make moving this container GPU powered
            isRenderGroup: true,
        });

        path = new Graphics();
        path.lineStyle(r(2), 0x010000, 1);
        path.beginFill(0xffffff, 1);
        path.drawRoundedRect(x, y, width, height, radius);
        path.endFill();
        
        container.addChild(path)
        
        const text = new Text(title, { fontFamily: 'Arial', fontSize: r(48), fill: 0x010000, align: 'center' });
        text.resolution = resolution
        text.x = x + width / 2 - text.width / 2;

        text.y = y+ height / 2 // + text.height / 2;
        
        
        container.addChild(text)
        path.interactive = true;
        // Mouse & touch events are normalized into
        // the pointer* events for handling different
        // button events.

const pointerOut = (path) => {
    return () => {
    container.removeChild(path)
    container.removeChild(text)
    
    path = new Graphics();
    path.interactive = true;
    path.lineStyle(r(2), 0xff0000, 1);
    path.beginFill(0xffffff, 1);
    path.drawRoundedRect(x, y, width, height, radius);
    path.endFill();

    path.on('pointerover', pointerOver(path))
    path.on('pointerout', pointerOut(path))

    container.addChild(path)
    container.addChild(text)
}
}

        const pointerOver = (path) => {
            return () => {
            
            container.removeChild(path)
            container.removeChild(text)
            path = new Graphics();
            path.interactive = true;
            path.lineStyle(r(10), 0x010000, 1);
            path.beginFill(0xffffff, 1);
            path.drawRoundedRect(x, y, width, height, radius);
            path.endFill();

            path.on('pointerover', pointerOver(path))
            path.on('pointerout', pointerOut(path))

            container.addChild(path)
            container.addChild(text)
    }
        }

        path.on('pointerover', pointerOver(path))
        path.on('pointerout', pointerOut(path));

        return container;
    }

    app.stage.addChild(createRoundedRectangle(r(200), r(200), r(400), r(200), r(30), "title"))

    app.ticker.add((time) => {
        if(bezier)
            app.stage.removeChild(bezier);

        curveY += window.innerHeight * time.deltaTime / 500;

        bezier = new Graphics();

        const fullWidth = window.innerWidth;
        const fullHeight = window.innerHeight * 0.6;
    
        bezier.bezierCurveTo(0, r(100), r(500), r(curveY), r(fullWidth), r(fullHeight/2));
        bezier.stroke({ width: r(100), color: 0xaa0000 });
        bezier.resolution = 2
        bezier.position.x = 0;
        bezier.position.y = 0;
        bezier.resolution = 2;
    
        app.stage.addChild(bezier);

        // Just for fun, let's rotate mr rabbit a little.
        // * Delta is 1 if running at 100% performance *
        // * Creates frame-independent transformation *
        // bunny.rotation += 0.1 * time.deltaTime;
    });
}
*/

function HomePage() {
    const [contactInformation, setContactInformation] = useState("");
    const [submittingContactInformation, setSubmittingContactInformation] = useState(false);

    const [containerSize, setContainerSize] = useState(100)
    rerenderFunction = setContainerSize

    const [submittedContacts, setSubmittedContacts] = useState([]);

    const gradientRef = React.useRef(null);

    const submitContactInformation = async () => {
        setSubmittingContactInformation(true);
        setSubmittedContacts([...submittedContacts, contactInformation]);

        await fetch("/api/contact", { method: "POST", headers: { "Content-Type": "application/json" }, body: JSON.stringify({ contactInformation, source: window.location.href }) })

        setSubmittingContactInformation(false);
    }

    const canvasRef = React.useRef(null);

    async function initApp() {
        if (canvasRef.current) {
           // initPixiApp(canvasRef.current)

        }
    }

    useEffect(() => {
        if(gradientCreated || !gradientRef.current) return

            // 
          //   var gradient = new Gradient()
            gradientCreated = true
          //  gradient.initGradient('#gradient-canvas');
        
    }, [gradientRef])

    useEffect(() => {
        initApp()
    }, [canvasRef.current])


    // const flow = getDefaultFlow()
// <Header />
    return (
        <>
          
            <div style={{ width: "100%", height: "55vh", position: "relative" }} >
                <ThreeJSView containerSize={containerSize}/>
                <div style={{ position: "absolute", width: "100%", height: "100%", top: "20px", left: "5vw", right: "20px", 
                letterSpacing: "-1px",
                color: "black", display: "flex" }}>
                    <h2 style={{display: "inline-block", position: "relative", top: "2px"}}>Floness</h2>
                    {false && <Fragment>
                    <h2 style={{marginLeft: "40px", display: "inline-block", fontSize: 15, lineHeight: "20px", position: "relative", top: "16px"}}>Products</h2>
                    <h2 style={{marginLeft: "30px", display: "inline-block", fontSize: 15, lineHeight: "20px", position: "relative", top: "16px"}}>Pricing</h2>
                    <h2 style={{marginLeft: "30px", display: "inline-block", fontSize: 15, lineHeight: "20px", position: "relative", top: "16px"}}>Try</h2>
                    <h2 style={{marginLeft: "30px", display: "inline-block", fontSize: 15, lineHeight: "20px", position: "relative", top: "16px"}}>About</h2>

                    </Fragment>}

                </div>
            <canvas ref={gradientRef} id="gradient-canvas" style={{position: "absolute", width:"100%",height:"100%"}}></canvas>
            <div style={{ 
                    position: "absolute", top: "20vh", left: "5vw", right: "50vw",
                    //WebkitTextStroke: "1px #ccc",
                    fontSize: "3rem",
                    lineHeight: "3rem",
                    fontWeight: "600",
                    color: "#000",
                    letterSpacing: "-2px",
                     //filter: "invert(1)",
 // mixBlendMode: "difference",
 filterOpacity: "0.1",
                    // scale: `${containerScale}%`, 
                    // width: `${containerSize}%`, height: `${containerSize}%`, position: "absolute", 
                    // top: `${containerPosition}%`, left:`${containerPosition}%`, 
                }}
                    ref={canvasRef} id="canvas"
                >
Accounting Beyond <span style={{fontWeight: 400}}>ChatGPT</span>&nbsp;


                </div>
  
              

                <div style={{ 
                    zIndex: 999,
                    position: "absolute", bottom: "calc(5vh - 1px)", left: "calc(5vw + 1px)", right: "50vw",
                    fontSize: "5rem",
                    opacity: "0.7",
                    lineHeight: "100px",
                    fontWeight: "900",
                    color: "#000",
                    letterSpacing: "-2px",
                     //filter: "invert(1)",
                    // scale: `${containerScale}%`, 
                    // width: `${containerSize}%`, height: `${containerSize}%`, position: "absolute", 
                    // top: `${containerPosition}%`, left:`${containerPosition}%`, 
                }}
                    id="canvas"
                >


                </div>
            </div>

            {false && <Fragment>
            <div style={{paddingLeft: "5vw", paddingRight: "5vw", paddingTop: "20px", paddingBottom: "20px", background: "white", position: "relative", zIndex: 999}}>

Yee test test 
</div>

            <video playsInline={true} autoPlay muted loop id="myVideo" style={{ display: "none", width: "100%", height: "60vh", "objectPosition": "50% 10%", objectFit: "cover", }}
                onClick={event => event.preventDefault()}
            >
                <source src="/floness_front.mov" />
            </video>

            <div style={{ background: palette.secondary.main, paddingBottom: "30px", paddingTop: "10px", marginTop: "-8px" }}>
                <Container maxWidth="lg" sx={{ paddingTop: "30px" }}>
                    <Grid container justify="center">
                        <Typography variant="h1" sx={{ fontSize: "3rem", fontWeight: "600" }} gutterBottom>
                            The Future of Productivity starts here.
                        </Typography>
                        <Typography variant="h6" sx={{ marginBottom: "30px" }}>
                            Combine human expertise with AI technology for unparalleled efficiency and success in your business processes.
                        </Typography>
                    </Grid>
                </Container>
            </div>
            <div style={{ background: palette.tertiary.main, paddingBottom: "30px" }}>

                <Container maxWidth="lg" sx={{ paddingTop: "30px" }}>
                    <Typography variant="h1" sx={{ marginLeft: '0px', paddingTop: "30px", fontSize: "40px", fontWeight: "light" }}>
                        The Floness Process:</Typography>
                </Container>
            </div>
            <div style={{ background: palette.tertiary.main, paddingBottom: "30px" }}>

                <Container maxWidth="sm" sx={{ paddingTop: "10px" }}>
                    <Grid container justify="center">
                        <Box sx={{ height: "280px", width: '100%' }} justifyContent={"center"}>
                            <WorkSplit width={"100%"} />
                        </Box>
                        <Typography variant="h1" sx={{ fontSize: "2.5rem", fontWeight: "600" }} gutterBottom>
                            <Typography sx={{ float: "left", marginLeft: { xs: "0px", md: "-64px" }, fontSize: "4rem", marginRight: "20px", fontWeight: "bold", position: "relative", top: { xs: 0, sm: 0, md: -33 } }}>1.</Typography>
                            Define and document your business process
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            First step in any business process optimization is to recognize the existence of the process and its steps.
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            Next, it must be documented as it is executed today. It consists of triggers, steps, nodes and branches.
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            That's easy with the <b>Floness Tool</b> leveraging latest AI technologies. It will help you to define and document the process.
                        </Typography>


                    </Grid>
                </Container>
            </div>

            <div style={{ background: palette.secondary.main, paddingBottom: "30px" }}>
                <Container maxWidth="sm" sx={{ paddingTop: "30px" }}>
                    <Grid container justify="center">
                        <Box sx={{ height: "min(50vh, 320px)", width: '100%' }} justifyContent={"center"}>
                            <LineConvergence width={"100%"} />
                        </Box>
                        <Typography variant="h1" sx={{ fontSize: "2.5rem", fontWeight: "600", marginTop: "30px" }} gutterBottom>
                            <Typography sx={{ float: "left", marginLeft: { xs: "0px", md: "-77px" }, fontSize: "4rem", marginRight: "20px", fontWeight: "bold", lineHeight: { xs: "60px", sm: "10px" }, position: "relative", top: { xs: 15, sm: 10, md: 10 } }}>2.</Typography>
                            Streamline your Business
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            After a process has been defined and documented, it's time to streamline and enforce it with the help of the <b>Floness Tool</b>.
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            It can be done by removing redundant steps and analyzing each step to determine how they can be improved. Everybody will be happy to be part of the smooth process.
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            Every decision in the flow will be done by the right person, AI or code. The end result will be a streamlined process that is efficient and successful every time.

                        </Typography>


                    </Grid>
                </Container>
            </div>
            <div style={{ background: palette.tertiary.main, paddingBottom: "30px" }}>
                <Container maxWidth="sm" sx={{ paddingTop: "30px" }}>
                    <Grid container justify="center">
                        <Box sx={{ paddingTop: "30px", paddingBottom: "30px", height: "min(20vh, 200px)", background: "white", width: '100%' }} justifyContent={"center"}>
                            <Circles width="100%" height="100%" />
                        </Box>
                        <Typography variant="h1" sx={{ fontSize: "2.5rem", fontWeight: "600", marginTop: "30px" }} gutterBottom>
                            <Typography sx={{ float: "left", marginLeft: { xs: "0px", md: "-77px" }, fontSize: "4rem", marginRight: "20px", fontWeight: "bold", lineHeight: { xs: "60px", sm: "10px" }, position: "relative", top: { xs: 15, sm: 10, md: 10 } }}>3.</Typography>
                            Re-focus your employees
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            Once a business process has been automated and is running smoothly, your employees can finally focus to tasks that require a human being.
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            The business process orchestration of <b>Floness Tool</b> will ask your employees to give the minimum input required. In practice, questions become simple.
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            Their workload and training requirements will go down. No more 3-month training for a new employee.
                        </Typography>


                    </Grid>
                </Container>
            </div>
            <div style={{ background: palette.secondary.main, paddingBottom: "30px" }}>
                <Container maxWidth="md" sx={{ paddingTop: "30px" }}>
                    <Grid container justify="center" justifyContent={"center"}>
                        <Grid item xs={12}>
                            <Typography textAlign={"center"} justifyContent={"center"} variant="h2" sx={{ fontSize: "2rem", fontWeight: "600" }} gutterBottom>
                                *
                            </Typography>
                            <Typography variant="h2" sx={{ fontSize: "2rem", fontWeight: "600" }} gutterBottom>
                                What are your business processes?
                            </Typography>

                            <Typography variant="body1" sx={{ marginBottom: "20px" }}>
                                Floness will help businesses define, document and automate their business processes using a hybrid: human + AI
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container justify="center" justifyContent={"center"} spacing={3} sx={{ marginTop: "20px" }}>
                        <Grid item xs={12} sm={6} sx={{ background: palette.secondary.main, borderRadius: "10px", minHeight: { xs: "0", sm: "240px" } }}>
                            <Typography variant="body1" sx={{ fontWeight: "600" }} gutterBottom>
                                <AddCardIcon sx={{ marginRight: "5px", position: "relative", top: "5px" }} /> Sales Process
                            </Typography>

                            <Typography variant="body1" gutterBottom>
                                What happens when you get a lead? Certainly something. But in fact, what?
                            </Typography>
                            <Typography variant="body1" gutterBottom>
                                Define and document it with our help. Finally, automate it.
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Typography variant="body1" sx={{ fontWeight: "600" }} gutterBottom>
                                <PersonAddAlt1Icon sx={{ marginRight: "5px", position: "relative", top: "5px" }} /> Customer Onboarding Process
                            </Typography>

                            <Typography variant="body1" gutterBottom>
                                A customer decides to buy. A contract is signed. What does really happen?
                            </Typography>
                            <Typography variant="body1" gutterBottom>
                                Onboard your customers in a process that's successful every time. Make them happy from the start.
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>

                            <Typography variant="body1" sx={{ fontWeight: "600" }} gutterBottom>
                                <AddModeratorIcon sx={{ marginRight: "5px", position: "relative", top: "5px" }} /> Regulated Processes



                            </Typography>

                            <Typography variant="body1" gutterBottom>
                                How does your team assess customer risks? Do you give credit without proper analysis?
                            </Typography>
                            <Typography variant="body1" gutterBottom>
                                Enforce a well-defined risk management process.
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} sx={{
                            background: palette.secondary.main, borderRadius: "10px", paddingRight: "15px",
                            minHeight: "240px"
                        }}>
                            <Typography variant="body1" sx={{ fontWeight: "600" }} gutterBottom>
                                <GroupAddIcon sx={{ marginRight: "5px", position: "relative", top: "5px" }} /> Hiring Process
                            </Typography>

                            <Typography variant="body1" gutterBottom>
                                Your team hired that person whose cat likes watching fish. And visited that game last Saturday. Great reasons for hiring!
                            </Typography>
                            <Typography variant="body1" gutterBottom>
                                Avoid hiring mistakes and define a process that is efficient and safe.
                            </Typography>
                        </Grid>

                    </Grid>







                </Container>
            </div>

            </Fragment>}
            <div style={{ background: "white", paddingBottom: "100px", }}>
                <Container maxWidth="md" sx={{ paddingTop: "30px" }}>
                    <Grid container justify="center">

                        <Typography variant="h1" sx={{ fontSize: "2.5rem", fontWeight: "600", textAlign: "right", marginRight: "10px", marginTop: "-30px" }} gutterBottom>
                            Want to stay in the loop? Please let us know.
                        </Typography>
                        <Typography variant="body1" sx={{ marginTop: "20px" }} gutterBottom>
                            You'll hear from us soon. We will add all emails to our newsletter.
                        </Typography>

                        <TextField value={contactInformation} onChange={event => setContactInformation(event.target.value)} sx={{ width: "calc(100% - 150px)" }} variant="outlined" placeholder="Your email or phone number" onKeyUp={event => {
                            if (event.keyCode === 13) {
                                submitContactInformation()
                            }
                        }}></TextField>
                        <Button disabled={submittingContactInformation} sx={{ marginLeft: "10px", background: palette.primary.main, color: "black" }} variant="outlined" color="primary" onClick={() => submitContactInformation()}>Contact us</Button>
                        <div>
                            {submittedContacts.length > 0 && <>
                                <Typography variant="h6" sx={{ marginTop: "20px" }} gutterBottom>
                                    <DoneIcon fontSize={"medium"} sx={{ position: "relative", top: "4px" }} /> Thank you for your interest. We will contact you soon.
                                </Typography>
                                <Typography variant="body1" gutterBottom>
                                    You did share the following contacts: {submittedContacts.join(", ")}
                                </Typography>
                            </>}
                        </div>
                    </Grid>

                </Container>
            </div>
            <Footer />
        </>);
}

export default HomePage;