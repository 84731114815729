import React from "react";
import { BrowserRouter as Router, Switch, Route, Routes } from 'react-router-dom'
import AboutPage from './pages/AboutPage';
import HomePage from './pages/HomePage';
import getTheme from './getTheme';
import { ThemeProvider } from '@mui/material';

function App() {
  return (
    <ThemeProvider theme={getTheme()}>
    <Router>
      <Routes>
        <Route path='/' exact element={<HomePage />} />
        <Route path='/about' element={<AboutPage />} /> 
      </Routes>
      </Router>
      </ThemeProvider>
    
  );
}

export default App;
