import React, { useRef, useEffect } from 'react';
import * as THREE from 'three';
import { CatmullRomCurve3 } from 'three';

const mouse = new THREE.Vector2();

function onMouseMove(event) {
  // Normalize mouse coordinates
  mouse.x = -0 +50 *( (event.clientX / window.innerWidth) * 2 - 1);
  mouse.y = -50 +100 *(-(event.clientY / window.innerHeight) * 4 + 1)


  // Update object position
  // cube.position.x = mouse.x * 5; // Adjust the factor for desired speed
  // / cube.position.y = mouse.y * 5;
}

window.addEventListener('mousemove', onMouseMove, false);

const ThreeJSView = (props) => {
  const mountRef = useRef(null);

  useEffect(() => {
    let frameId;
    const mount = mountRef.current;

    const circleScene = new THREE.Scene();
    const circleCamera =  new THREE.PerspectiveCamera(90
        , mount.clientWidth / mount.clientHeight, 0.1, 1000);
    
    circleCamera.position.x = -0;
    circleCamera.position.y = 0;
    circleCamera.position.z = 50;
    circleCamera.lookAt(new THREE.Vector3(0, 0, 0))

    // Create circles
    const circleGeometries = [];
    const circleMaterials = [];
    const colors = ['#ff0000' , '#00ff00', '#0000ff', '#ffff00', '#ff00ff'];
    const circles = [];

    colors.forEach((color) => {
      const geometry = new THREE.CircleGeometry(10, 32);
      const material = new THREE.MeshBasicMaterial({ color });
      const circle = new THREE.Mesh(geometry, material);
      circles.push(circle);
      circleScene.add(circle);
    });

    // Create random curves
    const createRandomCurve = () => {
      const points = [];
      for (let i = 0; i < 10; i++) {
        points.push(new THREE.Vector3(
          THREE.MathUtils.randFloatSpread(100),
          THREE.MathUtils.randFloatSpread(100),
          THREE.MathUtils.randFloatSpread(100)
        ));
      }
      return new CatmullRomCurve3(points);
    };

    const curves = circles.map(createRandomCurve);




    // Scene, Camera, Renderer
    const scene = new THREE.Scene();
    const camera = new THREE.PerspectiveCamera(75, mount.clientWidth / mount.clientHeight, 0.1, 1000);
    const renderer = new THREE.WebGLRenderer({ antialias: true });

    const renderTargets = [new THREE.WebGLRenderTarget(mount.clientWidth, mount.clientHeight), new THREE.WebGLRenderTarget(mount.clientWidth, mount.clientHeight)];
    const blurRenderTarget = new THREE.WebGLRenderTarget(mount.clientWidth, mount.clientHeight);


    renderer.setSize(mount.clientWidth, mount.clientHeight);
    mount.appendChild(renderer.domElement);

    // Load Texture
    const textureLoader = new THREE.TextureLoader();
    const texture = textureLoader.load('logo512.png'); // Replace with your texture path

    // Vertex Shader
    const vertexShader = `
      varying vec2 vUv;
      
      void main() {
        gl_Position = vec4(position, 1.0);
        vUv = uv;

      }
    `;
    // vUv = uv * 1.0 ;
    // gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);

    // Fragment Shader
    const fragmentShader = `
      uniform sampler2D uTexture;
      
      uniform float t;
      
      varying vec2 vUv;
      void main() {
        
        
        vec4 texColor = texture2D(uTexture, vUv);    

        if(t  == 0.0  ) {
            // gl_FragColor = vec4(1.0, 0.0, 0.0, 1.0);
            gl_FragColor = vec4(1.0, 0.0, 0.0, 1.0);
            //     gl_FragColor = texColor;
        } else {
            
            gl_FragColor = texColor;
        }
        gl_FragColor = texColor;
        
        
      }
    `;
    const fadeFragmentShader = `
    #define M_PI 3.1415926535897932384626433832795
    uniform sampler2D uTexture;
    
    uniform float t;
    
    varying vec2 vUv;
    void main() {
      vec4 texColor = texture2D(uTexture, vec2(vUv.x+0.0001, vUv.y));    
      vec4 fadeColor = vec4(1.0, 1.0, 1.0, 1.0);
      gl_FragColor = mix(texColor, fadeColor, 0.01);
    }
    `;

    const blurFragmentShader = `
    varying vec2 vUv;
    #define M_PI 3.1415926535897932384626433832795
    uniform sampler2D uTexture;          // texture to blur
    uniform float xs,ys;            // texture resolution
    uniform float r;                // blur radius
    uniform float t;

    uniform vec3 circle1Position;
    uniform vec3 circle2Position;
    uniform vec3 circle3Position;
    uniform vec3 circle4Position;
    uniform vec3 circle5Position;

    //---------------------------------------------------------------------------

    float rand(vec2 co){
        return sin(co.y/100.0);
        // return fract(sin(dot(co, vec2(12.9898, 78.233))) * 43758.5453);
    }
      
    int count = 5;

    void main()
        {
        vec3[] circles = vec3[](circle1Position, circle2Position, circle3Position, circle4Position, circle5Position);
        vec3 sortedPoints[5]; // Sorted array of vec3
        float distances[10];
        vec3 pos = vec3(0.0+100.0*vUv.x, 0.0+100.0*vUv.y, 0.0);



        // Initialize the sortedPoints and distances arrays
        for (int i = 0; i < 5; i++) {
            sortedPoints[i] = circles[i];
            distances[i] = distance(vec3(circles[i][0], circles[i][1], 0.0), pos);
        }
    
        // Bubble Sort algorithm
        for (int i = 0; i < count; i++) {
            for (int j = 0; j < count - i - 1; j++) {
                if (distances[j] > distances[j + 1]) {
                    // Swap distances
                    float tempDist = distances[j];
                    distances[j] = distances[j + 1];
                    distances[j + 1] = tempDist;
    
                    // Swap points
                    vec3 tempPoint = sortedPoints[j];
                    sortedPoints[j] = sortedPoints[j + 1];
                    sortedPoints[j + 1] = tempPoint;
                }
            }
        }

        float rr2dist = distance(sortedPoints[0], sortedPoints[1]);
        float rr2distForSin = rr2dist/75.0 * 2.0 * M_PI;

        float dist1 = distance(circles[0],pos);
        float dist2 = distance(circles[1],pos);
        float dist3 = distance(circles[2],pos);
        float dist4 = distance(circles[3],pos);
        float dist5 = distance(circles[4],pos);

        float rdist = (dist1+dist2)/2.0;
        float gdist = (dist2+dist3+dist4)/3.0;
        float bdist = (dist3+dist4+dist5)/3.0;

        vec4 texColor = texture2D(uTexture, vec2(vUv.x+0.0005, vUv.y+0.0005));    

        // gl_FragColor = vec4( 0.7 * distances[0]/rr2dist, 0.3+0.2*(sin(rr2distForSin)+1.0)/2.0, 0.4 + 0.6 * rr2dist/100.0, 1.0);
        // gl_FragColor = mix(vec4(  0.55 * 0.45 * distances[0]/(rr2dist*0.75), 0.2+0.8*rdist/150.0*gdist/75.0, 0.6 + 0.4 * bdist/75.0, 1.0), texColor, 0.9);
        gl_FragColor = mix(vec4(  
            // 5.0*log(1.0 * distances[0]/(rr2dist*1.5)), 
            0.4*log(0.5*distances[0]), 
            // 0.15*log(2.0*distances[1])+0.0*log(3.0*distances[0]), 
            1.0 - 0.2*log(0.2*distances[1]),
             0.25*log(0.5*distances[3]), 
            // 0.0 * 10.0 * log(distances[0]/(rr2dist*1.5)), 
            // 0.2+0.8*rdist/150.0*gdist/75.0, 
            // 0.0*(0.6 + 0.4 * bdist/75.0), 
            1.0), texColor, 0.5);

        float avg = (gl_FragColor.r + gl_FragColor.g + gl_FragColor.b)/3.0;
        
//        gl_FragColor = mix(gl_FragColor, vec4(avg, avg, avg, 1.0), 0.7);

    }


    `

    /*

        float distances[10]

        float x,y,xx,yy,rr=r*r,dx,dy,w,w0;
        w0=0.3780/pow(r,1.975);
        vec2 p;
        vec4 col=vec4(0.0,0.0,0.0,0.0);
        for (dx=5.0/xs,x=-r,p.x=0.5+(vUv.x*0.5)+(x*dx);x<=r;x++,p.x+=dx){ xx=x*x;
         for (dy=5.0/ys,y=-r,p.y=0.5+(vUv.y*0.5)+(y*dy);y<=r;y++,p.y+=dy){ yy=y*y;
          if (xx+yy<=rr )
            {
            w=w0*exp((-xx-yy)/(2.0*rr));
            col+=texture2D(uTexture,p)*w;
            }}}
        gl_FragColor=col;*/

    const geometry = new THREE.PlaneGeometry(2, 2);


    const blurMaterial = new THREE.ShaderMaterial({
        uniforms: {
            uTexture: { value: null },
            xs: { value: mount.clientWidth },
            ys: { value: mount.clientHeight },
            r: { value: 5.0 },
            t: { value: 1.0},
            circle1Position: { value: new THREE.Vector3(0, 0, 0) },
            circle2Position: { value: new THREE.Vector3(0, 0, 0) },
            circle3Position: { value: new THREE.Vector3(0, 0, 0) },
            circle4Position: { value: new THREE.Vector3(0, 0, 0) },
            circle5Position: { value: new THREE.Vector3(0, 0, 0) },
            },
            vertexShader,
            fragmentShader: blurFragmentShader,
        })

        const blurScene = new THREE.Scene();
        const blurMesh = new THREE.Mesh(geometry, blurMaterial);
        blurScene.add(blurMesh);
    
    
    
    // Shader Material
    const material = new THREE.ShaderMaterial({
      uniforms: {
        uTexture: { value: texture },
        
        vw: { value: mount.clientWidth },
        vh: { value: mount.clientHeight },
        t: { value: 0.0 }
      },
      vertexShader,
      fragmentShader
    });

    const fadeMaterial = new THREE.ShaderMaterial({
        uniforms: {
          uTexture: { value: renderTargets[0].texture },
          blurTexture: { value: blurRenderTarget.texture },
          vw: { value: mount.clientWidth },
          vh: { value: mount.clientHeight },
          t: { value: 0.0 },
        },
        vertexShader,
        fragmentShader: fadeFragmentShader
      });

    const aspect = mount.clientWidth / mount.clientHeight;

    // Create a Mesh with Custom Shader
    const mesh = new THREE.Mesh(geometry, material);
    scene.add(mesh);

    const fadeScene = new THREE.Scene();
    const fadeMesh = new THREE.Mesh(geometry, fadeMaterial);
    fadeScene.add(fadeMesh);

    camera.position.z = 5;
    

    // Animation logic
    const start = Date.now();
    const duration = 5000; // 5 seconds for the animation
    
    let turn = 0;
    mesh.position.set(0,0,0)
    mesh.scale.set(1,1,1)
    renderer.setRenderTarget(renderTargets[turn]);
    renderer.render(scene, camera);
    renderer.autoClearColor = false

    circles.forEach((circle, i) => {
        const curve = curves[i];
        const t = 0
        const point = curve.getPoint(t);
        
        circle.position.set(point.x+50, point.y+50, point.z);
        // circle.position.set(0,0,0)
      });

    const animate = () => {
        const time = Date.now() * 0.000005;

      circles.forEach((circle, i) => {
        const curve = curves[i];
        const t = (time*5 + i * 0.1) % 1; // Vary starting time for each circle
        const point = curve.getPoint(t);
        if(i == 0) {
        console.log(point.x, point.y)
        console.log(mouse.x, mouse.y, "mouse")
        point.x = (point.x + mouse.x) / 2
        point.y = (point.y + mouse.y) / 2
        
        }
        

        circle.position.set(point.x+50, point.y+50, point.z);
        blurMaterial.uniforms[`circle${i+1}Position`].value =circle.position
        // circle.position.set(0,0,0)
      });
      
      let nextTurn = 1 - turn;

        blurMaterial.uniforms.t.value = new Date().getTime() % 5000;
        blurMaterial.uniforms.uTexture.value = renderTargets[turn].texture;
        renderer.setRenderTarget(renderTargets[nextTurn]);
        renderer.render(blurScene, camera)

        renderer.setRenderTarget(null)
        renderer.render(blurScene, camera)

        
      // Animate the transformation
      //mesh.position.set((1 - t) * 10, (1 - t) * 10, 0); // Initially far away, moving towards (0, 0, 0)
      // mesh.scale.set(t, t, 1); // Initially very small, scaling up to normal size

          

      turn = 1 - turn;
      // renderer.setRenderTarget(renderTargets[turn]);
      
        // renderer.render(scene, camera);


      // Render the scene to the screen
      
      

      
        frameId = requestAnimationFrame(animate);
      
    };

    animate();

    // Clean up on unmount
    return () => {
      cancelAnimationFrame(frameId);
      mount.removeChild(renderer.domElement);
    };
  }, [props.containerSize]);

  return <div ref={mountRef} style={{ width: '120vw', height: '100%', position: "absolute", left: "-10vw", top: "-20px", transform: "rotate(-5deg)", border: "solid 2px black", transformOrigin: "10vw" }} />;
};

export default ThreeJSView;