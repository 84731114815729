import { AppBar, Box, Button, CssBaseline, Divider, Drawer, IconButton, List, ListItem, ListItemButton, ListItemText, Toolbar, Typography } from "@mui/material";
import React, { useState } from "react";

import MenuIcon from '@mui/icons-material/Menu';
import { useNavigate } from "react-router-dom";

const drawerWidth = 240;

let headerTextFunction

let headerTextIndex = 0

let headerTexts = [
  "Floness",
  <><b>flo</b>w + busi<b>ness</b></>,
  "Floness", 
  <>busi<b>ness</b> <b>flo</b>ws</>,
  "Floness", 
  <><b>flo</b>w busi<b>ness</b></>,
  "Floness", 
  "busi-flow-ness",
  "Floness",
  <>cash<b>floness</b></>,
  "Floness",
  "show-ness?"
]
setInterval(() => {
  headerTextIndex++

  if(headerTextIndex >= headerTexts.length) {
    headerTextIndex = 0
  }
  if(headerTextFunction)
  headerTextFunction(headerTexts[headerTextIndex])
}, 4000)

function Header() {
  const navigate = useNavigate();
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const [headerText, setHeaderText] = useState("Floness");

  headerTextFunction = setHeaderText

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
      <Typography variant="h6" sx={{ my: 2 }}>
        {headerText}
      </Typography>
      <Divider />
      <List>
        <ListItem key={"Home"} disablePadding sx={{display: "block"}}>
          <ListItemButton sx={{ textAlign: 'left' }}>
            <ListItemText primary={"Home"}  onClick={() => {
                navigate("/")
              }}/>
          </ListItemButton>
          <ListItemButton sx={{ textAlign: 'left' }}>
            <ListItemText primary={"About us"} onClick={() => {
                navigate("/about")
              }} />
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  );


  return (
    <Box sx={{ display: 'flex', marginBottom: "55px" }}>
      <CssBaseline />
      <AppBar component="nav">
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            variant="h6"
            component="div"
            sx={{ flexGrow: 1, display: {sm: 'block' } }}
          >
            {headerText}
          </Typography>
          <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
            <Button key={"Home"} sx={{ color: 'black', fontFamily: "Instrument Sans" }}
              onClick={() => {
                navigate("/")
              }}>
              {"Home"}
            </Button>
            <Button key={"About us"} sx={{ color: 'black' }} onClick={() => {
              navigate("/about")

            }}>
              {"About us"}
            </Button>
          </Box>
        </Toolbar>
      </AppBar>
      <nav>
        <Drawer

          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
      </nav>
    </Box>)
}

export default Header;