import React, { useState } from "react";

let step = 0;
let step2 = 0;
let stepFunction
const processLength = 250
let direction = 1
let circle2Trips = 0
setInterval(() => {

    step += direction
    step2 += direction
    if (stepFunction)
        stepFunction(step)
}, 30)

const circle1 = [58, 268]
const circle2 = [128, 268]

const circleRadius = 28.395039



const Circles = ({width, height}) => {

    const [_step, setStepState] = useState(0)
    stepFunction = setStepState

    const circle1Phase = step / processLength * 2 * Math.PI
    const circle1Dot = [circle1[0] + Math.cos(circle1Phase) * circleRadius, circle1[1] + Math.sin(circle1Phase) * circleRadius]

    let circle1Trips = Math.floor(step / processLength)


    const circle2ProcessLength = 250
    const circle2Step = step2 % circle2ProcessLength
    let circle2Phase
    if (circle2Step < circle2ProcessLength * 0.2) {
        circle2Phase = circle2Step / circle2ProcessLength * 2 * Math.PI
    } else if (circle2Step < circle2ProcessLength * 0.35) {
        circle2Phase = (0.1 + circle2Step / circle2ProcessLength) * 2 * Math.PI
    } else if (circle2Step < circle2ProcessLength * 0.5) {
        circle2Phase = (0.2 + circle2Step / circle2ProcessLength) * 2 * Math.PI
    } else {
        circle2Phase = (0.45 + circle2Step / circle2ProcessLength) * 2 * Math.PI
    }

    if (circle2Phase > 2 * Math.PI) {
        step2 = 0
        circle2Phase = 2 * Math.PI
        circle2Trips++
    }
    const circle2Dot = [circle2[0] + Math.cos(circle2Phase) * circleRadius, circle2[1] + Math.sin(circle2Phase) * circleRadius]



    return (

        <svg
            width={width}
            height={height}
            viewBox="0 0 136.2717 80.454552"
            version="1.1"
            id="svg1"
        >
            <defs
                id="defs1" />
            <g
                id="layer1"
                transform="translate(-27.164186,-228.59422)">
                <circle
                    style={{ fill: "none", stroke: "#3c3c3c", strokeWidth: 2, strokeDasharray: "none", strokeOpacity: 1 }}
                    id="path2"
                    cx={circle2[0]}
                    cy={circle2[1]}
                    r={circleRadius} />
                <circle
                    style={{ fill: "none", stroke: "#3c3c3c", strokeWidth: 2, strokeDasharray: "none", strokeOpacity: 1 }}
                    id="path2-4"
                    cx={circle1[0]}
                    cy={circle1[1]}
                    r={circleRadius} />
                <rect
                    style={{ fill: "#ffffff", fillOpacity: "1", stroke: "none", strokeWidth: "0.66003", strokeDasharray: "none", strokeOpacity: 1 }}
                    id="rect18"
                    width="51.24958"
                    height="20.315147"
                    x="94.188416"
                    y="259.47989"
                    ry="0.26062998" />
                <rect
                    style={{ fill: "#ffffff", fillOpacity: "1", stroke: "none", strokeWidth: "0.94467", strokeDasharray: "none", strokeOpacity: 1 }}
                    id="rect18-5"
                    width="52.936752"
                    height="40.288963"
                    x="-276.85165"
                    y="188.68117"
                    ry="0.51688087"
                    transform="matrix(0.28919924,-0.95726893,0.99715997,0.07531267,0,0)" />
                <rect
                    style={{ fill: "#ffffff", fillOpacity: 1, stroke: "none", strokeWidth: "0.502033", strokeDasharray: "none", strokeOpacity: 1 }}
                    id="rect18-0"
                    width="29.655859"
                    height="20.311256"
                    x="268.90814"
                    y="-154.18295"
                    ry="0.26058009"
                    transform="matrix(-0.04679328,0.99890459,-0.99998609,-0.0052742,0,0)" />
                <circle
                    style={{ fill: "#ffffff", fillOpacity: 1, stroke: "#3c3c3c", strokeWidth: 2, strokeDasharray: "none", strokeOpacity: 1 }}
                    id="path19"
                    cx={circle1Dot[0]}
                    cy={circle1Dot[1]}
                    r="4.6" />
                <circle
                    style={{ fill: "none", stroke: "#3c3c3c", strokeWidth: 2, strokeDasharray: "none", strokeOpacity: 0.207171 }}
                    id="path2-2"
                    cx={circle2[0]}
                    cy={circle2[1]}
                    r="28.395039" />
                <circle
                    style={{ opacity: "1", fill: "#ffffff", fillOpacity: 1, stroke: "#3c3c3c", strokeWidth: 2, strokeDasharray: "none", strokeOpacity: 1 }}
                    id="path19-2"
                    cx={circle2Dot[0]}
                    cy={circle2Dot[1]}
                    r="4.6" />

            </g>

            <text

                style={{
                    fontSize: "7.76111px", fill: "#333333", fillOpacity: 1, stroke: "none",
                    strokeWidth: 0.2, strokeDasharray: "none", strokeOpacity: 1
                }}
                x={circle1[0] + 20}
                y={circle1[1]}
                id="text22-3-5"><tspan
                    id="tspan22-5-5"
                    style={{
                        fontStyle: "normal", fontVariant: "normal",
                        fontWeight: "normal", fontStretch: "normal", fontSize: "24px", fontFamily: "Instrument Sans", fill: "#333333", fillOpacity: 1, stroke: "none", strokeWidth: 0.2, strokeDasharray: "none", strokeOpacity: 1
                    }}
                    y="42.304924" x={circle1[0] / 2+2} textAnchor="middle" dominantBaseline={"middle"}>{circle1Trips}</tspan></text>
 <text

style={{
    fontSize: "7.76111px", fill: "#333333", fillOpacity: 1, stroke: "none",
    strokeWidth: 0.2, strokeDasharray: "none", strokeOpacity: 1
}}
x={circle2[0] + 260}
y={circle2[1]}
id="text22-3-5"><tspan
    id="tspan22-5-5"
    style={{
        fontStyle: "normal", fontVariant: "normal",
        fontWeight: "normal", fontStretch: "normal", fontSize: "24px", fontFamily: "Instrument Sans", fill: "#333333", fillOpacity: 1, stroke: "none", strokeWidth: 0.2, strokeDasharray: "none", strokeOpacity: 1
    }}
    y="42.304924" x={100} textAnchor="middle" dominantBaseline={"middle"}>{circle2Trips}</tspan></text>

        </svg>

    )
}

export default Circles